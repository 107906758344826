'use strict'

angular
	.module('cb.lead-providers', [])
	.controller('LeadProviderDiaryController', ["$scope", "PickListService", "LeadProviderService", "NotificationService", function ($scope, PickListService, LeadProviderService, NotificationService) {



		$scope.$watch('startAtActive', function (n, o) {
			if (n != undefined && $scope.picklist != undefined) {
				$scope.search('activeLeadProviders', arrayFilter($scope.picklist, 'Active'), 'startAtActive', 'ActivePagination');
			}
		});

		$scope.$watch('startAtInProgress', function (n, o) {
			if (n != undefined && $scope.picklist != undefined) {
				$scope.search('inProgressLeadProviders', arrayFilter($scope.picklist, 'In Progress'), 'startAtInProgress', 'InProgressPagination');

			}
		});

		$scope.$watch('startAtSelected', function (n, o) {
			if (n != undefined && $scope.picklist != undefined) {
				$scope.search('selectedStatusLeadProviders', $scope.selectedStatus, 'startAtSelected', 'SelectedPagination');

			}
		});

		$scope.init = function () {
			$scope.selectedStatus = 1490;
			$scope.limit = 10;
			$scope.startAtActive = 1;
			$scope.startAtInProgress = 1;
			$scope.startAtSelected = 1;
			$scope.ActivePagination = {};
			$scope.InProgressPagination = {};
			$scope.SelectedPagination = {};
			$scope.selectedStatusLeadProviders = {};
			$scope.activeLeadProviders = {};
			$scope.inProgressLeadProviders = {};

			$scope.CollectionPromise = PickListService.getPickListByPickListName(['LeadProviderStatus']);
			$scope.CollectionPromise.then(function (output) {
				$scope.picklist = output.data.PickListEntries;
				$scope.search('activeLeadProviders', arrayFilter($scope.picklist, 'Active'), 'startAtActive', 'ActivePagination');
				$scope.search('inProgressLeadProviders', arrayFilter($scope.picklist, 'In Progress'), 'startAtInProgress', 'InProgressPagination');
			}, function (error) {

			});
		};

		$scope.OnStatusChange = function () {
			$scope.search('selectedStatusLeadProviders', $scope.selectedStatus, 'startAtSelected', 'SelectedPagination');
		};

		$scope.search = function (array, status, startAt, pagination) {
			var data = {}
			data.StatusId = status;
			data.Pagination = {};
			data.Pagination.StartPage = $scope[startAt] - 1;
			data.Pagination.PageSize = $scope.limit;
			$scope.SearchLeadProviderPromise = LeadProviderService.getLeadProvidersByStatus(data);
			$scope.SearchLeadProviderPromise.then(function (output) {
				$scope[array] = output.data.LeadProviders;
				$scope[pagination] = output.data.PaginationStatus;
			}, function (error) {
				$scope.fetchedDataError = true;
				NotificationService.alert('Lead Provider Error', 'There was an error retrieving the search results.', 'error', {});
			});

		};

		// Temp Function
		function randomDate(start, end) {
			return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
		};

		function arrayFilter(myArray, element) {

			for (var i = 0; i < myArray.length; i++) {
				if (myArray[i].PickListEntry === element) {
					return myArray[i].Id;
				}
			}
		}
	}])

	.controller('LeadProvidersController', ["$scope", "LeadProviderService", "NotificationService", function ($scope, LeadProviderService, NotificationService) {
		
		$scope.init = function () {
			$scope.startAt = 1;
			$scope.Pagination = {};
			$scope.selectedStatus = "";
		};

		$scope.$watch('startAt', function (n, o) {
			if (n != undefined && $scope.selectedStatus != "") {
				$scope.submit();

			}
		});

		$scope.submit = function () {
			var data = {
				IsPriority: $scope.selectedStatus,
				Pagination: {
					StartPage: $scope.startAt - 1,
					PageSize: 15
				}
			};

			$scope.SearchLeadProviderPromise = LeadProviderService.getPriorityLeadProviders(data);
			$scope.SearchLeadProviderPromise.then(function (output) {
				$scope.leadProviders = output.data.LeadProviders;
				$scope.Pagination = output.data.PaginationStatus;
			}, function (error) {
				$scope.fetchedDataError = true;
				NotificationService.alert('Lead Provider Error', 'There was an error retrieving the search results.', 'error', {});
			});
		};

		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};
	}])
	.controller('LeadProviderLeadsController', ["$scope", "LeadProviderService", "LeadProfileService", "NotificationService", "$routeParams", function ($scope, LeadProviderService, LeadProfileService, NotificationService, $routeParams) {
		$scope.PageSize = 20;
		$scope.OrderBy = 'Status';
		$scope.init = function () {
			$scope.Page = 0;
			$scope.lead={};
			$scope.Pages = [];
			$scope.lodeData = false;
			$scope.GetAllLeads($scope.PageSize,$scope.OrderBy);
			$scope.getLeadProvider();
		};

		$scope.GetAllLeads = function(size, sort){
			$scope.GetLeadsPromise = LeadProfileService.getLeadsByLeadProvider($routeParams.id, size, sort);
			$scope.GetLeadsPromise.then(function (output) {
				$scope.Pages = output.data.Pages;
				console.log($scope.Pages);
				$scope.lodeData = true;
			}, function (Error) {
				NotificationService.alert('Lead Provider Error', 'There was an error getting All Leads for this lead provider.', 'error', {});
			});
		}
		$scope.getLeadProvider = function () {
			$scope.GetLeadProviderPromise = LeadProviderService.getProviderById($routeParams.id);
			$scope.GetLeadProviderPromise.then(function (output) {
				$scope.lead = output.data.LeadProviderDetail;
			}, function (error) {
				NotificationService.alert('Lead Provider Error', 'There was an error getting this lead provider.', 'error', {});
			});
		};
		$scope.Next = function(){
			$scope.Page = $scope.Page+1
		};
		$scope.Back = function (){
			$scope.Page = $scope.Page-1
		};
	}])

	.controller('LeadProviderDetailsController', ["$rootScope", "$scope", "$routeParams", "$location", "$modal", "LeadProviderService", "LeadProfileService", "NotificationService", "PickListService", "EnquiryModalService", function ($rootScope,$scope, $routeParams, $location, $modal, LeadProviderService, LeadProfileService, NotificationService, PickListService, EnquiryModalService) {

		$scope.init = function () {
			$scope.selectedIndex = 1;
			$scope.Today = new Date();
			$scope.openPopups = {};
			$scope.AlternativeContactItem = {
				ContactName: null,
				TypeOfContactata: null,
				ContactData: null
			};
			if ($routeParams.id == 'new') {
				$scope.lead = {
					Address: {
						Postcode: $routeParams.Postcode
					},
					Id: 0
				};
			}
			else {
				$scope.lead = {
					Id: $routeParams.id
				};
				$scope.getLeadProvider();
			}
			$scope.originalLead = {
				Address: {}
			};				

			$scope.CollectionPromise = PickListService.getPickListByPickListName(['JobTitle', 'Title', 'BusinessType', 'altContactType', 'ConservatoryType', 'System', 'LeadProviderStatus', 'LeadProviderType', 'LeadProvCommission', 'LeadProvBusType', 'LeadProvCnsType', 'LeadProviderSource','LeadProvRecommend' ]);

			$scope.CollectionPromise.then(function (output) {
				$scope.picklist = output.data.PickListEntries;
			});

		};

		$scope.getLeadProvider = function () {
			$scope.GetLeadProviderPromise = LeadProviderService.getProviderById($scope.lead.Id);
			$scope.GetLeadProviderPromise.then(function (output) {
				$scope.lead = output.data.LeadProviderDetail;
				angular.copy($scope.lead, $scope.originalLead);
			}, function (error) {
				NotificationService.alert('Lead Provider Error', 'There was an error getting this lead provider.', 'error', {});
			});

			//GET LATEST NOTE HERE
			$scope.gettingNoteHistoryPromise = ($rootScope.onLineFlag) ? EnquiryModalService.getNoteHistory($scope.lead.Id) : IpadService.getNotesOrderId($scope.lead.Id);
			$scope.gettingNoteHistoryPromise.then(function (output) {
				if (output.data.Notes && output.data.Notes.length)
					$scope.LatestNote = output.data.Notes[0];
			}, function (error) {
				NotificationService.alert('Leadprovider Error', 'There was an error retrieving latest note.', 'error', {});
			});
		};

		$scope.$on('$locationChangeStart', function (event) {
			if (angular.equals($scope.lead, $scope.originalLead)) {
			}
			else {
				var answer = confirm("Unsaved data will be lost, are you sure you want to leave this page?")
				if (!answer) {
					event.preventDefault();
				}
			}
		});

		window.onbeforeunload = function (event) {
			if (angular.equals($scope.lead, $scope.originalLead)) { }
			else {
				var message = 'Unsaved data will be lost.Are you sure you want to close the page ?';
				if (typeof event == 'undefined') {
					event = window.event;
				}
				if (event) {
					event.returnValue = message;
				}
				return message;
			}
		}

		$scope.save = function () {
			$scope.leadCopy = {};
			angular.copy($scope.lead, $scope.leadCopy);
			var data = {
				LeadProvider: $scope.leadCopy
			};

			if ($scope.AlternativeContactItem.ContactName != null && $scope.AlternativeContactItem.TypeOfContactata != null && $scope.AlternativeContactItem.ContactData != null)
				data.LeadProvider.AlternativeContactItems.push($scope.AlternativeContactItem);
			
			// fixing dates that are defined in BST
			data.LeadProvider.AgreementStart = window.formatDate(data.LeadProvider.AgreementStart);
			data.LeadProvider.DataFirstReceived = window.formatDate(data.LeadProvider.DataFirstReceived);
			data.LeadProvider.DataLastReceived = window.formatDate(data.LeadProvider.DataLastReceived);

			$scope.SaveLeadProviderPromise = LeadProviderService.updateLeadProvider(data);
			$scope.SaveLeadProviderPromise.then(function (output) {
				if (window.location.href.indexOf('new') > 0) { //was a new lead provider that has just saved
					NotificationService.alert('Lead Provider Success', 'New Lead Provider has been created.', 'success', {});
					$location.path('/lead-provider-details/' + output.data.LeadProviderDetail.Id);
				}
				else {
					NotificationService.alert('Lead Provider Success', 'Lead Provider detail saved successfully.', 'success', {});
					$scope.getLeadProvider();
				}
				angular.copy($scope.lead, $scope.originalLead);
			}, function (error) {
				$scope.fetchedDataError = true;	
				NotificationService.alert('Lead Provider Error', 'There was an error saving the lead Provider.', 'error', {});
			});
		}

		$scope.openModal = function (modalTemplate, Controller, modalData, config) {
			return $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: modalTemplate,
				controller: Controller,
				resolve: {
					data: function () {
						return modalData;
					},
					Id: function () {
						return modalData;
					},
					config: function () {
						return config
					}
				},
			});
		};

		//opening and closing of datepickers
		$scope.open = function ($event, variable) {
			$event.preventDefault();
			$event.stopPropagation();

			for (var k in $scope.openPopups) {
				$scope.openPopups[k] = false;
			};
			$scope.openPopups[variable] = true;
		};

		$scope.openAddNoteModal = function (id) {
			$scope.openModal('views/modal-templates/add-note.html', 'AddNoteController', {
				ObjectId: id,
				ObjectName: "LeadProvider",
				Note: ""
			});
		};

		$scope.openFileLogModal = function (id) {
			var data = {
				id: id,
				CurrentProfile: $scope.lead.LPID,
				RequestArea: "LeadProvider"
			};
			$scope.openModal('views/modal-templates/file-log.html', 'FileLogController', data);
		};

		$scope.openNoteHistoryModal = function (id) {
			$scope.openModal('views/modal-templates/note-history.html', 'NoteHistoryController', id);
		};

		$scope.openActivityLogModal = function (id) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: "views/modal-templates/activity-log.html",
				controller: "ActivityLogController",
				resolve: {
					Id: function () {
						return id;
					}
				}
			});
		};

		$scope.AddFiles = function () {
			$scope.openModal('views/modal-templates/add-file.html', 'AddFile', {
				CurrentProfile: $scope.lead.LPID,
				RequestArea: "LeadProvider"
			});
		};

		$scope.openSendBrochureModal = function (id) {
			var Data = {
				ObjectId: id,
				ObjectType: 'lead provider',
				LetterType: 'brochure',
				LetterLocation: 'Lead Providers',
				ViewLocation: 'Brochure'
			};
			var config = {
				LetterType: 'Brochure'
			}
			$scope.openModal('views/modal-templates/send-letter.html', 'SendLetterController', Data, config);
		};

		$scope.openSendLetterModal = function (id) {
			var Data = {
				ObjectId: id,
				ObjectType: 'lead provider',
				LetterType: 'letter',
				LetterLocation: 'Lead Providers',
				ViewLocation: 'Letter'

			};
			var config = {
				LetterType: 'Standard'
			}
			$scope.openModal('views/modal-templates/send-letter.html', 'SendLetterController', Data, config);
		};

		//alternative contacts:

		$scope.updateAlternativeContact = function (altContact) {
			
			var contact = Object.assign({}, altContact);
			var modalInstance = $scope.openModal('views/modal-templates/update-contact.html', 'UpdateAlternativeContactItemController', {
				Contact: contact
			});
			modalInstance.result.then(function () {
				$scope.getLeadProvider();
			});
		}

		$scope.deleteAlternativeContact= function(Id){
			$scope.removingAlternativeContactPromise = LeadProfileService.removeAlternativeContactItem({ ContactId: Id });
			$scope.removingAlternativeContactPromise.then(function (success) {
				NotificationService.alert('Contact Removed', 'This alternative contact item has been removed.', 'success', {});
				$scope.getLeadProvider();
			}, function (err) {
				NotificationService.alert('Contact Removal Error', 'There was an error when attemtping to remove this contact.', 'error', {});
			});
		};

		// Temp Function
		function randomDate(start, end) {
			return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
		};
	}])
